<template>
  <div class="paginated-panel">
    <!-- <div class="header"> -->
    <slot name="header" />
    <!-- </div> -->
    <div class="content">
      <div v-for="(item, i) in currentItems" :key="`item#${i}`" class="item"> <slot name="content" :item="item" /></div>
    </div>
    <div class="footer">
      <div class="items-per-page">
        Afficher
        <v-select :model-value="`${itemsPerPage}`" :values="['10', '20', '50', '100']" @update:modelValue="itemsPerPage = +$event" /> lignes
      </div>
      <div class="current-lines">Lignes {{ `${start + 1}-${end}` }} sur {{ items.length }}</div>
      <div class="pagination">
        <button class="button shortcut first" :disabled="currentPage === 1" @click="currentPage = 1"><v-svg name="first-page" /></button>
        <button class="button shortcut first" :disabled="currentPage === 1" @click="currentPage--"><v-svg name="previous-page" /></button>

        <button v-for="page in pagination"
                :key="page"
                class="button page-number first"
                :class="{ current: page === currentPage }"
                @click="currentPage = page">
          {{ page }}
        </button>

        <button class="button shortcut first" :disabled="currentPage === numberOfPages" @click="currentPage++">
          <v-svg name="next-page" />
        </button>
        <button class="button shortcut first" :disabled="currentPage === numberOfPages" @click="currentPage = numberOfPages">
          <v-svg name="last-page" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue'
  export default {
    name: 'PaginatedPanel',
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    setup(props) {
      const currentPage = ref(1)
      const itemsPerPage = ref(10)
      const numberOfPages = computed(() => Math.ceil(props.items.length / itemsPerPage.value))
      const start = computed(() => (currentPage.value - 1) * itemsPerPage.value)
      const end = computed(() => Math.min(start.value + itemsPerPage.value, props.items.length))
      const currentItems = computed(() => props.items.slice(start.value, end.value))

      const pagination = computed(() => {
        const numberOfVisiblePage = 5
        const pagesBeforeAndAfter = (numberOfVisiblePage - 1) / 2
        const pages = Array.from({ length: numberOfPages.value }, (_, i) => i + 1)
        let paginationStart = Math.max(currentPage.value - 1 - pagesBeforeAndAfter, 0)
        let paginationEnd = Math.min(paginationStart + numberOfVisiblePage, numberOfPages.value)
        if (paginationEnd === numberOfPages.value) paginationStart = Math.max(paginationEnd - numberOfVisiblePage, 0)
        return pages.slice(paginationStart, paginationEnd)
      })

      return {
        currentPage,
        currentItems,
        itemsPerPage,
        numberOfPages,
        start,
        end,
        pagination
      }
    }
  }
</script>

<style lang="scss" scoped>
  .paginated-panel {
    background: var(--color-white);
    box-shadow: var(--shadow-default);
    padding: 2rem;
    border-radius: 1rem;

    .footer {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
      .items-per-page {
        display: flex;
        align-items: center;
        .v-select {
          margin: 0 0.5em;
        }
      }

      .current-lines {
        @extend %font-body-bold;
      }

      .pagination {
        justify-self: end;
        display: flex;
        .button {
          background: none;
          border: none;
          outline: none;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
          border-radius: 8px;
          cursor: pointer;
          &.page-number {
            &:hover {
              background: hsla(var(--hsla-second-accent), 0.25);
            }
            &.current {
              @extend %font-body-bold;
              background: var(--color-second-accent);
              color: var(--color-white);
            }
          }
          &.shortcut {
            color: var(--color-main-accent);
            &:hover:not([disabled]) {
              background: hsla(var(--hsla-main-accent), 0.25);
            }
            &[disabled] {
              cursor: not-allowed;
              opacity: 0.1;
            }
          }
        }
      }
    }
  }
</style>
